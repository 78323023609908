import React, { FC } from 'react';
import { css, cx } from 'emotion';

import { Tag, useStyles } from '@grafana/ui';
import { GrafanaTheme, PanelData } from '@grafana/data';

import { PlanButton } from '../../components/PlanButton';
import { BackendData } from '../../hooks';
import { MetricDisplay } from '../MetricDisplay';
import * as dataExtraction from '../../utils/dataExtraction';
import { MetricUnit } from 'types/MetricUnit';

interface MetricsProps {
  backendData: BackendData;
  panelData: PanelData;
}

const getStyles = (theme: GrafanaTheme) => {
  const img = theme.isDark ? 'homepage-bg.svg' : 'homepage-bg-light.svg';
  const imgUrl = `/public/plugins/cloud-home-links/img/${img}`;

  return {
    titleWrapper: css`
      align-items: center;
      display: flex;
      flex-grow: 1;
      width: 100%;
      margin-bottom: 12px;
      h1 {
        color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
        margin-bottom: unset;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
      }
    `,
    wrapper: css`
      align-items: center;
      background: ${theme.isDark ? 'bottom' : 'top'} left no-repeat url(${imgUrl});
      display: flex;
      flex-direction: row;
      height: 100%;
      flex-grow: 1;
      @media (max-width: 1000px) {
        align-items: unset;
        flex-direction: column;
      }
      @media (min-width: 2200px) {
        background-size: cover;
      }
    `,
    metrics: css`
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-around;
      @media (max-width: 1000px) {
        padding-left: 16px;
      }
    `,
    metricsShort: css`
      align-self: start;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-around;
      align-self: center;
      @media (max-width: 1000px) {
        padding-left: 16px;
        width: 100%;
      }
    `,
    trialTag: css`
      background-color: ${theme.isDark ? '#c65210' : theme.palette.white};
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-left: 10px;
    `,
    otherPLansTag: css`
      background-color: ${theme.isDark ? 'rgba(56, 60, 64, 0.7)' : theme.palette.white};
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      border: 1px solid #ca95e5;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-left: 10px;
    `,
    buttonWrapper: css`
      margin-top: 24px;
      @media (max-width: 1000px) {
        display: none;
      }
    `,
    buttonWrapperMobile: css`
      display: none;
      @media (max-width: 1000px) {
        display: flex;
        flex-grow: 0.5;
        align-self: center;
      }
    `,
    mobileHide: css`
      @media (max-width: 1000px) {
        display: none;
      }
    `,
    planInfo: css`
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      width: 80%;
      @media (max-width: 1000px) {
        display: none;
      }
    `,
    cloudPlan: css`
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      padding-left: ${theme.spacing.md};
      min-width: 382px;
    `,
  };
};

type PlanInfo = {
  [key: string]: { plan: string; tag: string; info: string };
};

const planInfo: PlanInfo = {
  free: {
    plan: 'free',
    tag: 'Free',
    info: 'Upgrade to Pro to monitor more workloads with longer retention, bring your whole team, and get access to reporting, advanced auth and more.',
  },
  trial: {
    plan: 'trial',
    tag: 'Free Trial',
    info: 'Enjoy full access to Grafana Cloud Pro during your trial, including unlimited metrics, logs and users, as well as reporting, advanced auth and more.',
  },
  pro: {
    plan: 'pro',
    tag: 'Pro',
    info: 'Check out a detailed breakdown of your usage and projected invoice in your Usage/Billing dashboard.',
  },
  advanced: {
    plan: 'advanced',
    tag: 'Advanced',
    info: 'Check out a detailed breakdown of your usage and projected invoice in your Usage/Billing dashboard.',
  },
};

const getPlanInfo = (isFreeTier: boolean, isTrialPlan: boolean, isAdvanced: boolean) => {
  let plan = 'pro';
  if (isFreeTier) {
    plan = 'free';
  } else if (isTrialPlan) {
    plan = 'trial';
  } else if (isAdvanced) {
    plan = 'advanced';
  }

  return planInfo[plan];
};

export const MetricsPanel: FC<MetricsProps> = ({ backendData, panelData }) => {
  const styles = useStyles(getStyles);
  const { users, dashboards, currentUser, currentUserOrgs } = backendData;
  const {
    getDashboards,
    getLogs,
    getMetrics,
    getTraces,
    getUsers,
    isFree,
    isTrial,
    isAdmin,
    isAdvanced,
    getOrgSlug,
    getK6Data,
  } = dataExtraction;
  const isFreeTier = isFree(panelData);
  const isTrialPlan = isTrial(panelData);
  const isAdvancedPlan = isAdvanced(panelData);
  const usersData = getUsers(users, isFreeTier, isTrialPlan);
  const dashboardsData = getDashboards(dashboards, isFreeTier);
  const metricsData = getMetrics(panelData);
  const logsData = getLogs(panelData);
  const tracesData = getTraces(panelData);
  const k6Data = getK6Data(panelData);
  const isAdminUser = isAdmin(currentUser, currentUserOrgs);
  const orgSlug = getOrgSlug(panelData);
  const planDetails = getPlanInfo(isFreeTier, isTrialPlan, isAdvancedPlan);
  const metricsUrl = isAdminUser ? '/a/grafana-easystart-app/hmInstancePromId' : undefined;
  const logsUrl = isAdminUser ? '/a/grafana-easystart-app/hlInstanceId' : undefined;
  const tracesUrl = isAdminUser ? '/a/grafana-easystart-app/htInstanceId' : undefined;
  const k6Url = isAdminUser ? '/plugins/k6-datasource' : undefined;

  return (
    <div className={styles.wrapper}>
      <div className={styles.cloudPlan}>
        <div className={styles.titleWrapper}>
          <h1>Welcome to Grafana Cloud!</h1>
          <Tag className={isTrialPlan ? styles.trialTag : styles.otherPLansTag} name={`Plan: ${planDetails.tag}`} />
        </div>
        {isAdminUser && <div className={styles.planInfo}>{planDetails.info}</div>}
        {isAdminUser && (isFreeTier || isTrialPlan) && (
          <div className={styles.buttonWrapper}>
            <PlanButton isFreePlan={isFreeTier} isTrialPlan={isTrialPlan} orgSlug={orgSlug} />
          </div>
        )}
      </div>
      <div className={cx('metrics-panel', isAdminUser ? styles.metrics : styles.metricsShort)}>
        <MetricDisplay
          {...dashboardsData}
          link={'/dashboards'}
          metric="Dashboards"
          isFree={isFreeTier}
          display={isAdminUser}
          unit={MetricUnit.DASHBOARDS}
        />
        <MetricDisplay
          link={'/org/users'}
          {...usersData}
          className={styles.mobileHide}
          metric="Users"
          isFree={isFreeTier}
          display={isAdminUser}
          unit={MetricUnit.USERS}
        />
        <MetricDisplay
          link={metricsUrl}
          {...metricsData}
          metric="Metrics"
          isFree={isFreeTier}
          display={true}
          unit={MetricUnit.METRICS}
        />
        <MetricDisplay
          link={logsUrl}
          {...logsData}
          metric="Logs"
          isFree={isFreeTier}
          display={true}
          unit={MetricUnit.LOGS}
        />
        <MetricDisplay
          link={tracesUrl}
          {...tracesData}
          metric="Traces"
          isFree={isFreeTier}
          display={true}
          unit={MetricUnit.TRACES}
        />
        <MetricDisplay
          link={k6Url}
          {...k6Data}
          metric="K6 tests"
          isFree={isFreeTier}
          display={true}
          unit={MetricUnit.K6_TESTS}
        />
      </div>
      {isAdminUser && (
        <div className={styles.buttonWrapperMobile}>
          <PlanButton isFreePlan={isFreeTier} isTrialPlan={isTrialPlan} orgSlug={orgSlug} />
        </div>
      )}
    </div>
  );
};
