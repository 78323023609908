import React, { FC } from 'react';
import { css } from 'emotion';

import { LinkButton, useStyles } from '@grafana/ui';
import { GrafanaTheme, PanelData } from '@grafana/data';

import { getOrgSlug, isAdmin, getBillingDashboardUrl } from '../../utils';
import { Icon, IconTypes } from '../icons';
import { CurrentUser, Dashboard, Org } from '../../api';

interface AdminPanelProps {
  currentUser: CurrentUser;
  currentUserOrgs: Org[];
  panelData: PanelData;
  dashboards: Dashboard[];
}

const getStyles = (theme: GrafanaTheme) => {
  const img = theme.isDark ? 'homepage-bg.svg' : 'homepage-bg-light.svg';
  const imgUrl = `/public/plugins/cloud-home-links/img/${img}`;

  return {
    wrapper: css`
      align-items: center;
      background: ${theme.isDark ? 'bottom' : 'top'} right no-repeat url(${imgUrl});
      display: flex;
      flex-direction: row;
      height: 100%;
      margin-left: 8px;
      padding: 16px;
      @media (max-width: 875px) {
        display: none;
      }
    `,
    links: css`
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    `,
    linksTitle: css`
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      font-size: 19px;
      line-height: 24px;
      font-weight: 400;
      @media (max-width: 1600px) {
        font-size: 15px;
      }
      @media (max-width: 1150px) {
        font-size: 12px;
      }
    `,
    link: css`
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      padding-left: 0px;
      font-weight: 400;
      line-height: 28px;
      height: 28px;
    `,
    linkText: css`
      margin-left: 7.33px;
      &:hover {
        text-decoration: underline;
      }
    `,
    linksList: css`
      list-style: none;
      margin-top: 5px;
    `,
  };
};

const getOrgLink = (orgSlug: string) =>
  `https://grafana.com/${orgSlug ? `orgs/${orgSlug}` : 'profile/org'}?src=hg_home`;

const getLinks = (orgSlug: string, dashboards: Dashboard[]) => [
  {
    text: 'Stacks',
    icon: IconTypes.BRIEFCASE,
    href: getOrgLink(orgSlug),
  },
  {
    text: 'Users',
    icon: IconTypes.USERS,
    href: `https://grafana.com/${orgSlug ? `orgs/${orgSlug}/members` : 'profile/org/members'}?src=hg_home`,
  },
  {
    text: 'API keys',
    icon: IconTypes.SHIELD,
    href: `https://grafana.com/${orgSlug ? `orgs/${orgSlug}/api-keys` : 'profile/org/api-keys'}?src=hg_home`,
  },
  {
    text: 'Billing/Usage dashboard',
    icon: IconTypes.BILLING,
    href: getBillingDashboardUrl(dashboards),
  },
  {
    text: 'Subscription',
    icon: IconTypes.BILL,
    href: `https://grafana.com/${orgSlug ? `orgs/${orgSlug}/subscription` : 'profile/org/subscription'}`,
  },
];

export const AdminPanel: FC<AdminPanelProps> = ({ currentUser, currentUserOrgs, panelData, dashboards }) => {
  const styles = useStyles(getStyles);
  const orgSlug = getOrgSlug(panelData);
  const isUserAdmin = isAdmin(currentUser, currentUserOrgs);

  if (!isUserAdmin) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.links}>
        <div className={styles.linksTitle}>Manage your account</div>
        <ul className={styles.linksList}>
          {getLinks(orgSlug, dashboards).map((link) => (
            <li key={link.text}>
              <LinkButton target="_blank" size={'md'} className={styles.link} fill="text" href={link.href}>
                <Icon logo={link.icon} />
                <span className={styles.linkText}>{link.text}</span>
              </LinkButton>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
