import React from 'react';
import { LinkButton } from '@grafana/ui';

interface PlanButtonProps {
  isFreePlan: boolean;
  isTrialPlan: boolean;
  orgSlug: string;
}

export const PlanButton = ({ isFreePlan, isTrialPlan, orgSlug }: PlanButtonProps) => {
  const text = 'Learn more and upgrade';
  const textPro = 'Go to Cloud Portal';
  const orgLink = `https://grafana.com/${orgSlug ? `orgs/${orgSlug}` : 'profile/org'}`;

  if (!isFreePlan && !isTrialPlan) {
    return (
      <LinkButton target="_blank" variant="primary" href={orgLink}>
        {textPro}
      </LinkButton>
    );
  }

  return (
    <LinkButton
      target="_blank"
      variant="primary"
      href={`https://grafana.com/${
        orgSlug ? `orgs/${orgSlug}/subscription` : 'profile/org/subscription'
      }?src=hg_home_btn`}
    >
      {text}
    </LinkButton>
  );
};
