import React from 'react';
import { css } from 'emotion';

import { stylesFactory, useStyles, LoadingPlaceholder } from '@grafana/ui';
import { GrafanaTheme } from '@grafana/data';

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  const img = theme.isDark ? 'homepage-bg.svg' : 'homepage-bg-light.svg';
  const imgUrl = `/public/plugins/cloud-home-links/img/${img}`;

  return {
    indicator: css`
      align-items: center;
      background: bottom left no-repeat url(${imgUrl});
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;

      div {
        font-size: 48px;
      }
    `,
  };
});

export const LoadingIndicator = () => {
  const styles = useStyles(getStyles);
  return <LoadingPlaceholder text="" className={styles.indicator} />;
};
