import React, { FC } from 'react';
import { css, cx } from 'emotion';

import { useStyles } from '@grafana/ui';
import { GrafanaTheme } from '@grafana/data';

import { UsageData } from '../../utils';
import { formatLimit, formatUsageValue } from 'utils/usageUtils';
import { MetricUnit } from 'types/MetricUnit';

interface MetricDisplayProps extends UsageData {
  metric: string;
  isFree: boolean;
  display: boolean;
  className?: string;
  link?: string;
  unit: MetricUnit;
}

const getStyles = (theme: GrafanaTheme) => {
  return {
    wrapper: css`
      @media (max-width: 1000px) {
        flex: 1 1 0px;
      }
    `,
    metric: css`
      font-size: 20px;
      line-height: 32px;
      @media (max-width: 650px) {
        font-size: 16px;
        line-height: 26px;
      }
    `,
    label: css`
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      font-size: 19px;
      font-weight: 300;
      line-height: 24px;
      @media (max-width: 1366px) {
        font-size: 14px;
        line-height: 19px;
      }
    `,
    usageText: css`
      font-size: 28px;
      color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      @media (max-width: 1366px) {
        font-size: 20px;
      }
    `,
    limitText: css`
      color: ${theme.isDark ? '#FFB375' : theme.palette.gray15};
      @media (max-width: 1400px) {
        font-size: 14px;
        white-space: nowrap;
      }
    `,
  };
};

export const MetricDisplay: FC<MetricDisplayProps> = ({
  usage,
  limit,
  metric,
  isFree,
  display,
  className,
  link,
  unit,
}) => {
  const styles = useStyles(getStyles);
  const limitText = formatLimit(limit, unit);
  const usageText = formatUsageValue(usage, unit, isFree);
  const limitSeparator = usage !== undefined ? '/' : '';
  const style = link ? { cursor: 'pointer' } : {};

  if (!display) {
    return null;
  }

  const handleClick = () => {
    if (link) {
      window.location.href = link;
    }
  };

  return (
    <div style={style} onClick={handleClick} className={cx('metrics-display', className, styles.wrapper)}>
      <div className={styles.metric}>
        {usage !== undefined && <span className={cx('metrics-display__usage', styles.usageText)}>{usageText}</span>}
        {isFree && <span className={styles.limitText}>{`${limitSeparator}${limitText}`}</span>}
      </div>
      <div className={cx('metrics-display__label', styles.label)}>{metric}</div>
    </div>
  );
};
