import React, { useEffect, useRef } from 'react';
import { css } from 'emotion';

import { useStyles } from '@grafana/ui';
import { GrafanaTheme, PanelProps } from '@grafana/data';

import { SimpleOptions } from '../../types';
import { useBackendSrv } from '../../hooks';
import { RequestsState } from '../../api';
import { LoadManager } from '../LoadManager';
import { MetricsPanel } from '../MetricsPanel';
import { AdminPanel } from '../AdminPanel';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = (theme: GrafanaTheme) => {
  return {
    titleWrapper: css`
      padding-left: ${theme.spacing.md};
      h1 {
        color: ${theme.isDark ? theme.palette.white : theme.palette.gray15};
      }
    `,
    contentWrapper: css`
      display: flex;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    `,
    wrapper: css`
      background-color: ${theme.isDark ? 'rgb(17, 18, 23)' : 'rgb(244, 245, 245)'};
      height: 100%;
      width: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
  };
};

export const CloudHomeWelcome: React.FC<Props> = ({ data }) => {
  const styles = useStyles(getStyles);
  const elementRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const backendData = useBackendSrv();
  const isLoading = backendData.requestsState === RequestsState.REQUESTED;

  // We need to unset the padding and border from the wrappers of the panel
  // so that we can follow the design and have a panel with a full background
  useEffect(() => {
    const divElement: HTMLDivElement = elementRef.current;
    const parent = divElement?.parentNode as HTMLElement | null;
    const grandParent = parent?.parentNode as HTMLElement | null;

    if (parent) {
      parent.style.padding = 'unset';
    }

    if (grandParent) {
      grandParent.style.border = 'unset';
    }
  }, []);

  return (
    <div ref={elementRef} className={styles.wrapper}>
      <LoadManager requestsState={backendData.requestsState} error={backendData.error} loading={isLoading}>
        <div className={styles.contentWrapper}>
          <MetricsPanel backendData={backendData} panelData={data} />
          <AdminPanel
            currentUser={backendData.currentUser}
            currentUserOrgs={backendData.currentUserOrgs}
            panelData={data}
            dashboards={backendData.dashboards}
          />
        </div>
      </LoadManager>
    </div>
  );
};
