import React, { FC } from 'react';

export const Billing: FC<{}> = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.66208 7.66146H0.996578C0.819811 7.66146 0.650284 7.73168 0.525291 7.85667C0.400298 7.98166 0.330078 8.15119 0.330078 8.32796V12.9935C0.330078 13.1702 0.400298 13.3398 0.525291 13.4647C0.650284 13.5897 0.819811 13.66 0.996578 13.66H5.66208C5.83884 13.66 6.00837 13.5897 6.13336 13.4647C6.25836 13.3398 6.32858 13.1702 6.32858 12.9935V8.32796C6.32858 8.15119 6.25836 7.98166 6.13336 7.85667C6.00837 7.73168 5.83884 7.66146 5.66208 7.66146ZM4.99558 12.327H1.66308V8.99446H4.99558V12.327ZM12.9936 0.329956H8.32808C8.15131 0.329956 7.98178 0.400176 7.85679 0.525169C7.7318 0.650162 7.66158 0.819689 7.66158 0.996456V5.66196C7.66158 5.83872 7.7318 6.00825 7.85679 6.13324C7.98178 6.25824 8.15131 6.32846 8.32808 6.32846H12.9936C13.1703 6.32846 13.3399 6.25824 13.4649 6.13324C13.5899 6.00825 13.6601 5.83872 13.6601 5.66196V0.996456C13.6601 0.819689 13.5899 0.650162 13.4649 0.525169C13.3399 0.400176 13.1703 0.329956 12.9936 0.329956ZM12.3271 4.99546H8.99458V1.66296H12.3271V4.99546ZM12.9936 7.66146H8.32808C8.15131 7.66146 7.98178 7.73168 7.85679 7.85667C7.7318 7.98166 7.66158 8.15119 7.66158 8.32796V12.9935C7.66158 13.1702 7.7318 13.3398 7.85679 13.4647C7.98178 13.5897 8.15131 13.66 8.32808 13.66H12.9936C13.1703 13.66 13.3399 13.5897 13.4649 13.4647C13.5899 13.3398 13.6601 13.1702 13.6601 12.9935V8.32796C13.6601 8.15119 13.5899 7.98166 13.4649 7.85667C13.3399 7.73168 13.1703 7.66146 12.9936 7.66146ZM12.3271 12.327H8.99458V8.99446H12.3271V12.327ZM5.66208 0.329956H0.996578C0.819811 0.329956 0.650284 0.400176 0.525291 0.525169C0.400298 0.650162 0.330078 0.819689 0.330078 0.996456V5.66196C0.330078 5.83872 0.400298 6.00825 0.525291 6.13324C0.650284 6.25824 0.819811 6.32846 0.996578 6.32846H5.66208C5.83884 6.32846 6.00837 6.25824 6.13336 6.13324C6.25836 6.00825 6.32858 5.83872 6.32858 5.66196V0.996456C6.32858 0.819689 6.25836 0.650162 6.13336 0.525169C6.00837 0.400176 5.83884 0.329956 5.66208 0.329956ZM4.99558 4.99546H1.66308V1.66296H4.99558V4.99546Z"
      fill="currentColor"
    />
  </svg>
);
