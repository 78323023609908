import { formatStorageCapacity } from './bytes';
import { MetricUnit } from '../types/MetricUnit';

const displayGbShort = (usage: number): string => {
  return formatStorageCapacity(usage, 'GB').replace('bytes', 'B').replace(' ', '');
};

export function formatUsageValue(usage = 0, unit: MetricUnit, isFree: boolean): string {
  switch (unit) {
    case MetricUnit.LOGS:
    case MetricUnit.TRACES:
      if (isFree) {
        return usage === 0 ? '0' : displayGbShort(usage);
      } else {
        return formatStorageCapacity(usage, 'GB');
      }

    case MetricUnit.USERS:
    case MetricUnit.DASHBOARDS:
      return usage.toLocaleString();

    case MetricUnit.METRICS:
      return handleCompactFormat(usage);

    case MetricUnit.K6_TESTS:
      return `${handleCompactFormat(usage)}${isFree ? '' : ' VUh'}`;

    default:
      return `${usage}`;
  }
}

export function pluralizeWord(count: number, word: string, pluralWord = word + 's'): string {
  return [1, -1].includes(Number(count)) ? word : pluralWord;
}

export function handleCompactFormat(value = 0): string {
  return value >= 1000
    ? `${
        value % 1000 > 0
          ? (value / 1000).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
          : (value / 1000).toLocaleString()
      }k`
    : value.toLocaleString();
}

export function formatLimit(limit = 0, unit: MetricUnit) {
  const limitText = handleCompactFormat(limit);

  switch (unit) {
    case MetricUnit.LOGS:
    case MetricUnit.TRACES:
      return `${limitText} GB max`;

    case MetricUnit.K6_TESTS:
      return `${limitText} VUh max`;

    default:
      return `${limitText} max`;
  }
}
