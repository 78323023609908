export type CurrentUser = {
  authLabels: string[];
  avatarUrl: string;
  createdAt: string;
  email: string;
  id: number;
  isDisabled: boolean;
  isExternal: boolean;
  isGrafanaAdmin: boolean;
  login: string;
  name: string;
  orgId: number;
  theme: string;
  updatedAt: string;
};

export type User = {
  authLabels: string[];
  avatarUrl: string;
  email: string;
  id: number;
  isAdmin: boolean;
  isDisabled: boolean;
  lastSeenAt: string;
  lastSeenAtAge: string;
  login: string;
  name: string;
};

export type Dashboard = {
  id: number;
  isStarred: boolean;
  slug: string;
  sortMeta: number;
  tags: string[];
  title: string;
  type: string;
  uid: string;
  uri: string;
  url: string;
};

export type Alert = {
  dashboardId: number;
  dashboardSlug: string;
  dashboardUid: string;
  evalData: any;
  evalDate: string;
  executionError: string;
  id: number;
  name: string;
  newStateDate: string;
  panelId: number;
  state: string;
  url: string;
};

export enum ROLE {
  ADMIN = 'Admin',
  VIEWER = 'Viewer',
  EDITOR = 'Editor',
}

export type Org = {
  orgId: number;
  name: string;
  role: ROLE;
};

export enum RequestsState {
  NOTREQUESTED,
  REQUESTED,
  SUCCEEDED,
  ERROR,
}
