import { useEffect, useState } from 'react';
import { isAdmin } from '../utils';

import { Alert, CurrentUser, Dashboard, backendSrv, RequestsState, User, Org } from '../api';
import { BackendData } from './types';

const currentUSerInit: CurrentUser = {
  authLabels: [],
  avatarUrl: '',
  createdAt: '',
  email: '',
  id: 0,
  isDisabled: false,
  isExternal: false,
  isGrafanaAdmin: false,
  login: '',
  name: '',
  orgId: 1,
  theme: '',
  updatedAt: '',
};

export const useBackendSrv = (): BackendData => {
  const [requestsState, setRequestsState] = useState(RequestsState.NOTREQUESTED);
  const [error, setError] = useState('');
  const [users, setUsers] = useState([] as User[]);
  const [currentUser, setCurrentUser] = useState(currentUSerInit);
  const [alerts, setAlerts] = useState([] as Alert[]);
  const [dashboards, setDashboards] = useState([] as Dashboard[]);
  const [currentUserOrgs, setCurrentUserOrgs] = useState([] as Org[]);
  const { getCurrentUser, getDashboards, getAlerts, getUsers, getCurrentUserOrgs } = backendSrv;

  useEffect(() => {
    setRequestsState(RequestsState.REQUESTED);
    getCurrentUser()
      .then((currentuserData) => {
        setCurrentUser(currentuserData);
        getCurrentUserOrgs()
          .then((orgsData) => {
            setCurrentUserOrgs(orgsData);
            if (isAdmin(currentuserData, orgsData)) {
              getUsers()
                .then((usersData) => {
                  setUsers(usersData);
                  getAlerts()
                    .then((alertsData) => {
                      setAlerts(alertsData);
                      getDashboards()
                        .then((dashboardsData) => {
                          setDashboards(dashboardsData);
                          setRequestsState(RequestsState.SUCCEEDED);
                        })
                        .catch((error: Error) => {
                          setError(error.message);
                          setRequestsState(RequestsState.ERROR);
                        });
                    })
                    .catch((error: Error) => {
                      setError(error.message);
                      setRequestsState(RequestsState.ERROR);
                    });
                })
                .catch((error) => {
                  setError(error.message);
                  setRequestsState(RequestsState.ERROR);
                });
            } else {
              setRequestsState(RequestsState.SUCCEEDED);
            }
          })
          .catch((error: Error) => {
            setError(error.message);
            setRequestsState(RequestsState.ERROR);
          });
      })
      .catch((error) => {
        setError(error.message);
        setRequestsState(RequestsState.ERROR);
      });
  }, []);

  return {
    requestsState,
    error,
    users,
    currentUser,
    currentUserOrgs,
    alerts,
    dashboards,
  };
};
