// Alerts
export const FREE_ALERTS_INCLUDED = 100;
export const PROTRIAL_ALERTS_INCLUDED = -1; // unlimited

// Dashboards
export const FREE_DASHBOARDS_INCLUDED = 10;
export const PROTRIAL_DASHBOARDS_INCLUDED = -1; // unlimited

// Logs
export const LOGS_FREE_INCLUDED = 50; // gb
export const LOGS_INFO = 'grafanacloud_logs_instance_info';
export const LOGS_INCLUDED = 'grafanacloud_org_logs_included_usage';
export const LOGS_USAGE = 'grafanacloud_org_logs_usage';
export const LOGS_PRO_INCLUDED = -1; // unlimited

// Metrics
export const METRICS_FREE_INCLUDED = 10000;
export const METRICS_INFO = 'grafanacloud_instance_info';
export const METRICS_INCLUDED = 'grafanacloud_org_metrics_included_series';
export const METRICS_USAGE_FREE = 'grafanacloud_instance_active_series';
export const METRICS_USAGE_PRO = 'grafanacloud_org_metrics_billable_series';

export const METRICS_PRO_INCLUDED = -1; // unlimited

// Traces
export const TRACES_FREE_INCLUDED = 50;
export const TRACES_INFO = 'grafanacloud_traces_instance_info';
export const TRACES_INCLUDED = 'grafanacloud_org_traces_included_usage';
export const TRACES_USAGE = 'grafanacloud_org_traces_usage';
export const TRACES_PRO_INCLUDED = -1;

// Users
export const PROTRIAL_USERS_INCLUDED = 10;
export const USERS_FREE_INCLUDED = 3;
export const USERS_PRO_INCLUDED = -1; // unlimited

// K6
export const K6_FREE_INCLUDED = 500; // VUh
export const K6_USAGE = 'grafanacloud_org_k6_virtual_user_hours_usage';
export const K6_INCLUDED = 'grafanacloud_org_k6_virtual_user_hours_included_usage';
export const K6_PRO_INCLUDED = -1; // unlimited

// Info
export const ORG_INFO = 'grafanacloud_org_info';

// Date Created
export const DATE_CREATED = 'grafanacloud_instance_created_date';

// Notifications
export const ONE_DAY = 1000 * 60 * 60 * 24;
