import React, { ReactNode } from 'react';

import { LoadingIndicator } from '../LoadingIndicator';
import { Error } from '../Error';
import { RequestsState } from 'api';

interface LoadManagerProps {
  loading: boolean;
  error?: string;
  children: ReactNode;
  requestsState: RequestsState;
}

export const LoadManager: React.FC<LoadManagerProps> = ({ loading, error, children, requestsState }) => {
  if (loading) {
    return <LoadingIndicator />;
  }

  if (error || requestsState === RequestsState.ERROR) {
    return <Error message={error} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};
