type Base = 2 | 10;

const getBaseMultiplier = (base: Base) => {
  if (base === 10) {
    return 1000;
  }
  return 1024;
};

const unitsByPower = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] as const;

export type StorageUnit = typeof unitsByPower[number];

export interface StorageCapacity {
  amount: number;
  unit: StorageUnit;
}

/**
 * Format the amount from bytes to a human-readable string
 * eg 1024 GB on a 2 base will be formatted to 1 TB
 * @param bytes the number of bytes
 * @param baseMultiplier the base multiplier that can be 1024 (by default) or 1000.
 * @param precision the precision can be 2 (by default) or 10
 */
export const formatBytes = (bytes: number, baseMultiplier = 1024, precision = 2): StorageCapacity => {
  if (bytes === 0) {
    return { amount: 0, unit: 'bytes' };
  }
  const realPower = Math.log(bytes) / Math.log(baseMultiplier);
  const unit = unitsByPower[Math.floor(realPower)];
  return { amount: Number((bytes / baseMultiplier ** unitsByPower.indexOf(unit)).toFixed(precision)), unit };
};

export const convertToBytes = (amount: number, unit: StorageUnit, baseMultiplier = 1024) =>
  amount * baseMultiplier ** unitsByPower.indexOf(unit);

export type Options = {
  precision?: number;
  base?: Base;
};

/**
 * Converts the amount into bytes and then formats it to a human-readable string with
 * the most suitable denomination for example if unit is 'GB' amount refers to the amount of gigabytes
 * This will be converted to bytes and then formatted to the most suitable denomination for viewing
 * eg 1024 GB on a 2 base will be formatted to 1 TB
 * @param amount the amount of the denomination to be converted
 * @param unit the unit of the amount received
 * @param {precision,base} default base is 2, but can be 10, defined the base value to calculate the denomination of byte, either 1024 or 1000
 * default precision is 1, but can be any number, defines the number of decimal places to display
 */
export const getStorageCapacityFrom = (
  amount: number | string,
  unit: StorageUnit,
  options?: Options
): StorageCapacity | null => {
  const { precision = 1, base = 2 } = options || {};
  const parsedAmount = parseFloat(`${amount}`);
  if (isNaN(parsedAmount)) {
    return null;
  }
  const baseMultiplier = getBaseMultiplier(base);
  const bytes = convertToBytes(parsedAmount, unit, baseMultiplier);
  return formatBytes(bytes, baseMultiplier, precision);
};

/**
 * Format Storage capacity to {amount} {unit} or '-' if the amount is not a number
 * @param amount the amount of the denomination to be converted
 * @param unit the unit of the amount received
 * @param {precision,base} default base is 2, but can be 10, defined the base value to calculate the denomination of byte, either 1024 or 1000
 * default precision is 1, but can be any number, defines the number of decimal places to display
 */
export const formatStorageCapacity = (amount: number | string, unit: StorageUnit, options?: Options): string => {
  const data = getStorageCapacityFrom(amount, unit, options);
  if (!!data) {
    return `${data.amount} ${data.unit}`;
  }
  return '-';
};
