import React, { FC } from 'react';

import { BriefCase } from './BriefCase';
import { Shield } from './Shield';
import { Users } from './Users';
import { Bill } from './Bill';
import { Billing } from './Billing';

import { IconTypes } from './types';

interface IconProps {
  logo: IconTypes;
}

export const Icon: FC<IconProps> = ({ logo }) => {
  switch (logo) {
    case IconTypes.BRIEFCASE:
      return <BriefCase />;
    case IconTypes.SHIELD:
      return <Shield />;
    case IconTypes.USERS:
      return <Users />;
    case IconTypes.BILL:
      return <Bill />;
    case IconTypes.BILLING:
      return <Billing />;
    default:
      return null;
  }
};
