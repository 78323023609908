import React, { FC } from 'react';
import { css } from 'emotion';

import { stylesFactory, useStyles, Icon } from '@grafana/ui';
import { GrafanaTheme } from '@grafana/data';

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  const img = theme.isDark ? 'homepage-bg.svg' : 'homepage-bg-light.svg';
  const imgUrl = `/public/plugins/cloud-home-links/img/${img}`;

  return {
    error: css`
      align-items: center;
      background: bottom left no-repeat url(${imgUrl});
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      font-size: 24px;
    `,
    icon: css`
      margin-right: 10px;
    `,
  };
});

interface ErrorProps {
  message?: string;
}

export const Error: FC<ErrorProps> = ({ message }) => {
  const styles = useStyles(getStyles);
  return (
    <div className={styles.error}>
      <Icon className={styles.icon} name="exclamation-triangle" size="xxl" />
      {message || 'There was an issue, please try again'}
    </div>
  );
};
