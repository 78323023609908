import { getBackendSrv } from '@grafana/runtime';
import { Alert, CurrentUser, Dashboard, Org, User } from './types';

function get<D>(url: string): Promise<D> {
  return getBackendSrv().get(url);
}

export const getUsers = async (): Promise<User[]> => {
  return await get('api/org/users');
};

export const getCurrentUser = async (): Promise<CurrentUser> => {
  return await get('api/user');
};

export const getAlerts = async (): Promise<Alert[]> => {
  return await get('api/alerts');
};

export const getDashboards = async (): Promise<Dashboard[]> => {
  return await get('api/search?type=dash-db');
};

export const getCurrentUserOrgs = async (): Promise<Org[]> => {
  return await get('api/user/orgs');
};

export const backendSrv = {
  getAlerts,
  getCurrentUser,
  getCurrentUserOrgs,
  getDashboards,
  getUsers,
};

export const getAllBackendData = (): Promise<[User[], Alert[], Dashboard[]]> => {
  return Promise.all([getUsers(), getAlerts(), getDashboards()]);
};
