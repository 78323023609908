import { PanelData, DataFrame } from '@grafana/data';

import { Alert, CurrentUser, Dashboard, Org, ROLE, User } from '../api';
import { UsageData } from './types';
import {
  METRICS_FREE_INCLUDED,
  LOGS_FREE_INCLUDED,
  USERS_FREE_INCLUDED,
  METRICS_INCLUDED,
  METRICS_PRO_INCLUDED,
  LOGS_INCLUDED,
  LOGS_USAGE,
  LOGS_PRO_INCLUDED,
  PROTRIAL_USERS_INCLUDED,
  USERS_PRO_INCLUDED,
  PROTRIAL_DASHBOARDS_INCLUDED,
  PROTRIAL_ALERTS_INCLUDED,
  FREE_DASHBOARDS_INCLUDED,
  FREE_ALERTS_INCLUDED,
  ORG_INFO,
  DATE_CREATED,
  ONE_DAY,
  TRACES_FREE_INCLUDED,
  TRACES_PRO_INCLUDED,
  TRACES_INCLUDED,
  TRACES_USAGE,
  K6_USAGE,
  K6_FREE_INCLUDED,
  K6_INCLUDED,
  K6_PRO_INCLUDED,
} from './consts';
import { METRICS_USAGE_FREE, METRICS_USAGE_PRO } from '.';

const getSeries = (series: DataFrame[], name: string): DataFrame | undefined => {
  return series?.find(
    (series) => series?.name?.indexOf(name) === 0 || series?.fields?.some((f) => f.name.indexOf(name) === 0)
  );
};

const getValueField = (series: DataFrame | undefined, name: string) => {
  if (series?.fields && series.fields?.length > 0) {
    return (
      series.fields.find((field) => field.name === 'Value') ?? series.fields.find((f) => f.name.indexOf(name) === 0)
    );
  }
  return null;
};

const getValue = (series: DataFrame | undefined, name: string) => {
  const valueField = getValueField(series, name);

  return valueField?.values?.get(0);
};

const getLabels = (series: DataFrame | undefined, name: string) => {
  const valueField = getValueField(series, name);

  return valueField?.labels;
};

export const isAdmin = (user: CurrentUser, orgs: Org[]) => {
  const { orgId } = user;
  const currentOrg = orgs.find((org) => org.orgId === orgId);
  return currentOrg?.role === ROLE.ADMIN;
};

export const getMetrics = (data: PanelData): UsageData => {
  let limit;
  let usage;
  const isFreeTier = isFree(data);
  const metrics = isFreeTier ? METRICS_USAGE_FREE : METRICS_USAGE_PRO;

  if (data.series.length === 0) {
    // inactive free account
    return { usage: 0, limit: METRICS_FREE_INCLUDED };
  }

  const limitSeries = getSeries(data.series, METRICS_INCLUDED);
  const usageSeries = getSeries(data.series, metrics);
  limit = isTrial(data) ? getValue(limitSeries, METRICS_INCLUDED) : METRICS_PRO_INCLUDED;
  usage = getValue(usageSeries, metrics);

  if (isFreeTier) {
    limit = METRICS_FREE_INCLUDED;
  }

  return {
    limit,
    usage,
  };
};

export const getLogs = (data: PanelData) => {
  let limit;
  let usage;

  if (data.series.length === 0) {
    // inactive free account
    return { usage: 0, limit: LOGS_FREE_INCLUDED };
  }

  const limitSeries = getSeries(data?.series, LOGS_INCLUDED);
  const usageSeries = getSeries(data?.series, LOGS_USAGE);
  limit = isTrial(data) ? getValue(limitSeries, LOGS_INCLUDED) : LOGS_PRO_INCLUDED;
  usage = getValue(usageSeries, LOGS_USAGE);

  if (isFree(data)) {
    limit = LOGS_FREE_INCLUDED;
  }

  return {
    limit,
    usage,
  };
};

export const getTraces = (data: PanelData) => {
  let limit;
  let usage;

  if (data.series.length === 0) {
    // inactive free account
    return { usage: 0, limit: TRACES_FREE_INCLUDED };
  }

  const limitSeries = getSeries(data?.series, TRACES_INCLUDED);
  const usageSeries = getSeries(data?.series, TRACES_USAGE);
  limit = isTrial(data) ? getValue(limitSeries, TRACES_INCLUDED) : TRACES_PRO_INCLUDED;
  usage = getValue(usageSeries, TRACES_USAGE);

  if (isFree(data)) {
    limit = TRACES_FREE_INCLUDED;
  }

  return {
    limit,
    usage,
  };
};

export const getK6Data = (data: PanelData) => {
  let limit;
  let usage;

  if (data.series.length === 0) {
    // inactive free account
    return { usage: 0, limit: K6_FREE_INCLUDED };
  }

  const limitSeries = getSeries(data?.series, K6_INCLUDED);
  const usageSeries = getSeries(data?.series, K6_USAGE);
  limit = isTrial(data) ? getValue(limitSeries, K6_INCLUDED) : K6_PRO_INCLUDED;
  usage = getValue(usageSeries, K6_USAGE);

  if (isFree(data)) {
    limit = K6_FREE_INCLUDED;
  }

  return {
    limit,
    usage,
  };
};

export const getUsers = (users: User[], isFree: boolean, isTrial: boolean): UsageData => {
  let limit = USERS_PRO_INCLUDED;
  // Grafana instances create a default admin user that is not counted
  // for cloud limits
  const filteredUsers = users.filter((user) => user.email !== 'admin@localhost');

  if (isFree) {
    limit = USERS_FREE_INCLUDED;
  } else if (isTrial) {
    limit = PROTRIAL_USERS_INCLUDED;
  }

  return {
    limit,
    usage: filteredUsers.length,
  };
};

export const isFree = (data: PanelData): boolean => {
  const orgInfoSeries = getSeries(data?.series, ORG_INFO);
  const info = getLabels(orgInfoSeries, ORG_INFO);

  if (!data.series.length) {
    return true;
  }

  return info?.org_grafana_cloud === 'free';
};

export const isTrial = (data: PanelData): boolean => {
  if (data.series.length === 0) {
    // free account
    return false;
  }

  const orgInfoSeries = getSeries(data?.series, ORG_INFO);
  const info = getLabels(orgInfoSeries, ORG_INFO);

  return info?.org_grafana_cloud === 'trial';
};

export const isAdvanced = (data: PanelData): boolean => {
  if (data.series.length === 0) {
    // free account
    return false;
  }

  const orgInfoSeries = getSeries(data?.series, ORG_INFO);
  const info = getLabels(orgInfoSeries, ORG_INFO);

  return info?.org_contract_type === 'contracted';
};

export const getOrgSlug = (data: PanelData): string => {
  // free account, we won't get this data from the data
  if (data.series.length === 0) {
    return '';
  }

  const orgInfoSeries = getSeries(data?.series, ORG_INFO);
  const info = getLabels(orgInfoSeries, ORG_INFO);

  return info ? info.org_slug : '';
};

export const getDashboards = (dashboards: Dashboard[], isFree: boolean): UsageData => {
  // Grafana's api count all dashboards, but the billing one does not count towards
  // the free tier limit of 10 dashboards
  const filteredDashboards = dashboards.filter((dashboard) => dashboard.title !== 'Grafana Cloud Billing/Usage');

  return {
    usage: filteredDashboards.length,
    limit: isFree ? FREE_DASHBOARDS_INCLUDED : PROTRIAL_DASHBOARDS_INCLUDED,
  };
};

export const getAlerts = (alerts: Alert[], isFree: boolean): UsageData => {
  return {
    usage: alerts.length,
    limit: isFree ? FREE_ALERTS_INCLUDED : PROTRIAL_ALERTS_INCLUDED,
  };
};

export const getInstanceCreation = (data: PanelData): number | undefined => {
  if (data.series.length === 0) {
    // free account
    return undefined;
  }

  const createdSeries = getSeries(data?.series, DATE_CREATED);

  return getValue(createdSeries, DATE_CREATED);
};

export const isTrialAboutToExpire = (data: PanelData): boolean => {
  const isTrialPlan = isTrial(data);
  const instanceCreation = getInstanceCreation(data);
  const week = ONE_DAY * 7;
  const now = new Date();
  const aWeekAgo = now.getTime() - week;

  if (!isTrialPlan || !instanceCreation) {
    return false;
  }

  return instanceCreation < aWeekAgo;
};

export const getBillingDashboardUrl = (dashboards: Dashboard[]) => {
  const billingDashboard = dashboards.find((dashboard) => dashboard.uri?.indexOf('billing-usage') !== -1);

  return billingDashboard ? billingDashboard.url : '';
};
